import React, { useState } from 'react'
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { PageProps } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image"
import DateIcon from '../assets/date.svg'
import PinIcon from '../assets/pin.svg'
import Button from '../components/Button'
import { formatDateRange, nl2jsx, transformStaticPath } from '../utils'
import Modal from 'react-modal'
import NetlifyForm from '../components/NetlifyForm'
import * as styles from './DentalKurse.module.scss'
import YoutubeVideo from '../components/YoutubeVideo'
import { useIntl } from "gatsby-plugin-intl"

type DentalKurseQueryProps = PageQuery<{
  hero: HeroImageData<IGatsbyImageData>;
  title: string;
  body: MarkdownField;
  video?: string;
  kurse: {
    title: string;
    image: IGatsbyImageData;
    start: string;
    end: string;
    location: string;
    text: string;
    price: string;
    notice?: string;
    pdf?: string;
  }[];
}>

const DentalKursePage = ({ data }: PageProps<DentalKurseQueryProps>) => {
  const { locale, formatMessage } = useIntl()
  const { markdownRemark } = data
  const frontmatter = markdownRemark.frontmatter[locale]!
  const [selectedKurs, setSelectedKurs] = useState<typeof frontmatter['kurse'][number]>()

  const onSubmitRegisterForm = () => {
    alert(formatMessage({id: 'courses.confirmMessage'}))
    setSelectedKurs(undefined)
  }

  const renderKursForm = () => (
    <NetlifyForm
      name={'kurs-anmeldung-' + locale}
      onSubmit={onSubmitRegisterForm}
      className={styles.registerForm}
      submitButtonProps={{
        children: formatMessage({id: 'courses.register'}),
        icon: 'register',
        align: 'stretch',
        noMarginBottom: true,
        type: 'submit',
      }}
      hiddenFields={[{
        label: formatMessage({id: 'courses.course'}),
        value: selectedKurs?.title || '',
      }, {
        label: formatMessage({id: 'courses.date'}),
        value: selectedKurs ? formatDateRange(selectedKurs.start, selectedKurs.end) : '',
      }, {
        label: formatMessage({id: 'courses.location'}),
        value: selectedKurs?.location || '',
      }]}
      rows={[
        {
          label: formatMessage({id: 'form.company'}),
          type: 'string',
        },
        [
          {
            label: formatMessage({id: 'form.firstname'}),
            type: 'string',
            required: true,
          }, {
            label: formatMessage({id: 'form.lastname'}),
            type: 'string',
            required: true,
          }
        ], {
          label: formatMessage({id: 'form.street'}),
          type: 'string',
          required: true,
        }, [
          {
            label: formatMessage({id: 'form.zip'}),
            type: 'string',
            required: true,
          }, {
            label: formatMessage({id: 'form.city'}),
            type: 'string',
            required: true,
          }
        ], {
          label: formatMessage({id: 'form.email'}),
          type: 'string',
          required: true,
        }, {
          label: formatMessage({id: 'form.phone'}),
          type: 'string',
        }, {
          label: formatMessage({id: 'courses.participants'}),
          type: 'select',
          options: [
            '1 ' + formatMessage({id: 'courses.participants'}),
            '2 ' + formatMessage({id: 'courses.participants'}),
            '3 ' + formatMessage({id: 'courses.participants'}),
            '4 ' + formatMessage({id: 'courses.participants'}),
          ]
        }, {
          label: formatMessage({id: 'form.remarks'}),
          type: 'text',
        }
      ]}
    >
      <div className={styles.priceSection}>
        <div className={styles.price}>
          {selectedKurs?.price} {formatMessage({id: 'courses.priceLabel'})}
        </div>
        {selectedKurs?.notice && <div>
          {nl2jsx(selectedKurs.notice)}
        </div>}
      </div>
    </NetlifyForm>
  )

  return (
    <Layout
      seo={{title: frontmatter.title, description: frontmatter.body.html}}
      hero={frontmatter.hero}
      introCard={{
        title: frontmatter.title,
        body: frontmatter.body.html,
      }}
      wide
    >
      <div style={{height: '2rem'}} />
      {frontmatter.video && <YoutubeVideo videoIdOrLink={frontmatter.video} fullWidth />}
      <div className={styles.cards}>
        {frontmatter.kurse.map((kurs, index) => {
          const kursImage = kurs.image && getImage(kurs.image)

          return (
            <div key={index} className={styles.card}>
              {kursImage && (
                <GatsbyImage
                  image={kursImage}
                  alt={kurs.title}
                />
              )}
              <div className={styles.title}>
                {kurs.title}
              </div>
              {kurs.pdf && <div className={styles.pdfLink}>
                <a href={transformStaticPath(kurs.pdf)} target='_blank'>{formatMessage({id: 'courses.moreInformation'})}</a>
              </div>}
              <div className={styles.iconRow}>
                <span className={styles.icon}><DateIcon /></span>
                <span className={styles.label}>
                  {formatDateRange(kurs.start, kurs.end)}
                </span>
              </div>
              <div className={styles.iconRow}>
                <span className={styles.icon}><PinIcon /></span>
                <span className={styles.label}>{kurs.location}</span>
              </div>
              <div className={styles.text}>
                {kurs.text}
              </div>
              <div className={styles.priceSection}>
                <div className={styles.price}>
                  {kurs.price} {formatMessage({id: 'courses.priceLabel'})}
                </div>
                {kurs?.notice && <div>
                  {nl2jsx(kurs.notice)}
                </div>}
              </div>
              <Button
                icon='register'
                align='stretch'
                noMarginBottom
                onClick={() => setSelectedKurs(frontmatter.kurse[index])}
              >{formatMessage({id: 'courses.registerShort'})}</Button>
            </div>
          )
        })}
        {new Array(3).fill(0).map((_, i) => <div key={i} className={[styles.card, styles.filler].join(' ')} />)}
      </div>
      <Modal
        isOpen={selectedKurs !== undefined}
        onRequestClose={() => setSelectedKurs(undefined)}
        className={styles.registerModal}
        overlayClassName={styles.registerModalOverlay}
      >
        <div className={styles.title}>
          {selectedKurs?.title}
        </div>
        {selectedKurs?.pdf && <div className={styles.pdfLink}>
          <a href={transformStaticPath(selectedKurs?.pdf)} target='_blank'>{formatMessage({id: 'courses.moreInformation'})}</a>
        </div>}
        <div className={styles.iconRow}>
          <span className={styles.icon}><DateIcon /></span>
          <span className={styles.label}>{selectedKurs && formatDateRange(selectedKurs.start, selectedKurs.end)}</span>
          <span className={styles.icon}><PinIcon /></span>
          <span className={styles.label}>{selectedKurs?.location}</span>
        </div>
        {selectedKurs?.text}
        {renderKursForm()}
      </Modal>
      {/* Render form statically for Netlify Forms to recognize */}
      <div style={{display: 'none'}}>{renderKursForm()}</div>
    </Layout>
  )
}

export const pageQuery = graphql`
  fragment DentalKurseFragment on FrontmatterLocalized {
    hero {
      image {
        desktopHero: childImageSharp {
          gatsbyImageData(
            width: 1920
            aspectRatio: 2.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
        mobileHero: childImageSharp {
          gatsbyImageData(
            width: 760
            aspectRatio: 1.5
            placeholder: BLURRED
            transformOptions: {cropFocus: CENTER}
          )
        }
      }
    }
    title
    body {
      html
    }
    video
    kurse {
      title
      image {
        childImageSharp {
          gatsbyImageData(
            width: 320
            aspectRatio: 1.5
            placeholder: BLURRED
          )
        }
      }
      start
      end
      location
      text
      price
      notice
      pdf
    }
  }

  query DentalKursePage {
    markdownRemark(fileAbsolutePath: {regex: "/pages\/dental-kurse.md/"}) {
      frontmatter {
        de {
          ...DentalKurseFragment
        }
        en {
          ...DentalKurseFragment
        }

      }
    }
  }
`

export default DentalKursePage
