// extracted by mini-css-extract-plugin
export var cards = "DentalKurse-module--cards--18OIr";
export var card = "DentalKurse-module--card--1B_fN";
export var filler = "DentalKurse-module--filler--25WcR";
export var title = "DentalKurse-module--title--3QF3h";
export var pdfLink = "DentalKurse-module--pdfLink--IGYAY";
export var iconRow = "DentalKurse-module--iconRow--39HKe";
export var icon = "DentalKurse-module--icon--1aEzn";
export var label = "DentalKurse-module--label--3dmcz";
export var text = "DentalKurse-module--text--gWeBi";
export var priceSection = "DentalKurse-module--priceSection--1vJmT";
export var price = "DentalKurse-module--price--f7pKD";
export var registerModal = "DentalKurse-module--registerModal--30Z17";
export var registerForm = "DentalKurse-module--registerForm--38bKL";
export var registerModalOverlay = "DentalKurse-module--registerModalOverlay--1F5YG";